import { useRouter } from 'next/router';
import { editorialCategoryProps } from '@/components/Home/FeaturedEditorialCategory';
import classNames from '@/utils/className';
import { editorialCategoryType } from '@/lib/sponsors';
import ImageComponent from '../Image';

interface SponsorBannerProps {
  isInternal?: boolean;
  isFeatured?: boolean;
  variant: editorialCategoryType;
}

const variants = {
  EditorialCategoryLogoContainer: {
    isInternal: {
      true: 'isInternal rounded-t-lg rounded-b-none [&.isInternal]:tablet:pb-6 [&.isInternal]:tablet:pb-6 [&>svg]:w-[108px] [&>a>svg]:w-[108px] [&>svg]:h-[33px] [&>a>svg]:h-[33px] p-4',
      false: '',
    },
    isFeatured: {
      true: 'isFeatured [&.isFeatured]:py-4 [&.isFeatured]:px-0',
      false: '',
    },
    backgroundColor: {
      ctup: 'bg-[#004BCC]',
      eletro: 'bg-[#1C1C1C]',
      ctauto: 'bg-[#E0140F]',
    },
  },
  EditorialCategorySponsorContainer: {
    editorialCategory: {
      ctup: 'flex flex-col before:block before:text-mglGray50 text-sm before:content-[attr(data-before)]',
    },
  },
} as const;

const SponsorContainer = ({ editorialCategory }: { editorialCategory: SponsorBannerProps['variant'] }) => {
  const responsiveLogoClass = 'first:[&>figure]:block';

  const options = {
    ctup: (
      <span data-before="Conteúdo apoiado por:" className={classNames('flex', variants.EditorialCategorySponsorContainer.editorialCategory.ctup)}>
        <div className="mt-2 flex justify-center gap-4">
          <a
            className={classNames('z-[2] flex items-center', responsiveLogoClass)}
            href="https://www.intel.com.br/?utm_source=canaltech&utm_medium=afiliados"
            rel="sponsored"
            title="Ir para o site da Intel"
            target="_blank">
            <ImageComponent
              src="https://t.ctcdn.com.br/ot8EIL7T2KzVKQeKqvktF5YXGCs=/i985051.png"
              blurhash="L4TI:ipy0*u6u65V5U5V9x9x9x9x"
              alt="Intel"
              height={26}
              width={67}
              rounded={false}
            />
          </a>
          <a
            className={classNames('z-[2] flex items-center', responsiveLogoClass)}
            href="https://www.kabum.com.br/?origem=360&utm_source=canaltech&utm_medium=afiliados"
            rel="sponsored"
            title="Ir para o site da KaBuM!"
            target="_blank">
            <ImageComponent
              src="https://t.ctcdn.com.br/CpiGsI7zY15tlW4RN0ibegYqkbE=/i923545.png"
              blurhash="LpLEjIS6-nbc_4oKofayW8s+NIsl"
              alt="KaBuM!"
              height={26}
              width={77}
              rounded={false}
            />
          </a>
        </div>
      </span>
    ),
    eletro: (
      <div className="z-[2] flex items-center first:block first:h-8 first:w-8 last:hidden last:h-[30px] last:w-[88px] tablet:block first:tablet:hidden" />
    ),
    ctauto: (
      <div className="z-[2] flex items-center first:block first:h-8 first:w-8 last:hidden last:h-[30px] last:w-[88px] tablet:block first:tablet:hidden" />
    ),
  };

  return options[editorialCategory];
};

export default function SponsorBanner({ isInternal = undefined, isFeatured = undefined, variant }: SponsorBannerProps) {
  const router = useRouter();
  const hasLogo = !!editorialCategoryProps[variant]?.logo;
  const EditorialCategoryLogo = () => {
    if (typeof router.query.categorias === 'string' && Object.keys(editorialCategoryProps).includes(router.query.categorias)) {
      return <>{editorialCategoryProps[variant]?.logo}</>;
    }
    if (hasLogo) {
      return <a href={`/${variant}/`}>{editorialCategoryProps[variant]?.logo}</a>;
    }
    return null;
  };

  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center gap-4 px-6 py-4 tablet:flex-row tablet:justify-between tablet:pb-12 [&>a]:h-8 [&>svg]:h-8',
        isFeatured && variants.EditorialCategoryLogoContainer.isFeatured[isFeatured.toString()],
        isInternal && variants.EditorialCategoryLogoContainer.isInternal[isInternal.toString()],
        variants.EditorialCategoryLogoContainer.backgroundColor[variant]
      )}>
      {hasLogo && <EditorialCategoryLogo />}
      <SponsorContainer editorialCategory={variant} />
    </div>
  );
}
